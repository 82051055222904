<template>
  <div id="root">
    <div class="login">
      <div style="font-size: 1.25rem">登录</div>
      <div style="margin-top: 1rem">
        <el-input
          placeholder="请输入账号"
          prefix-icon="el-icon-user"
          v-model="account"
          clearable>
        </el-input>
      </div>
      <div  style="margin-top: 1rem">
        <el-input
          placeholder="请输入密码"
          prefix-icon="el-icon-key"
          v-model="password"
          show-password
          clearable>
        </el-input>
      </div>
      <el-button   style="margin-top: 2rem" type="primary" @click="netGetUserByAccount()">登录</el-button>
    </div>
  </div>
</template>

<script>

import storage from '@/store/storage';
import { getUserByAccount } from '@/api/login'

export default {
  components: {
  },
  data () {
    return {
      account: '',
      password: ''
    }
  },
  created () {
    if (storage.get('user') != null) {
      this.$router.push('/todolist')
    }
  },
  methods: {
    login () {
      let user = {
        userID: 'x2rgm',
        token: '07402A4551BDD3A4B8474E710710AF541334123B5937A1AEA1319543FB5848F4'
      }
      storage.set('user', user)
    },
    netGetUserByAccount () {
      if (this.account.trim().length == 0) {
        this.$message.error('账号不能为空')
        return
      }
      if (this.password.trim().length == 0) {
        this.$message.error('密码不能为空')
        return
      }

      let account = this.account.trim()
      let password = this.password.trim()

      console.log(account)
      console.log(password)

      let params = {
        account: this.account.trim(),
        password: this.password.trim()
      }
      getUserByAccount(params).then(res => {
        const data = res.data
        console.log(data)
        if (data.vipTime == null || data.vipTime == '') {
          this.$message.error('您账号尚未开通会员，请在App中开通')
          return
        }
        if (data.vipTime < new Date().getTime()) {
          this.$message.error('您账号会员已过期，请在App中续费')
          return
        }
        // 清空原有的数据
        storage.remove('user')
        this.db.plan.clear()
        this.db.planCollection.clear()

        storage.set('user', data)
        this.$router.push('/todolist')
      })
    }
  }
}
</script>

<style>
#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: red; /* 不支持线性的时候显示 */
  background-image: linear-gradient(to bottom right, #fe917c , #fedcaf);
}
.login{
  display: flex;
  flex-direction: column;
  width: 20rem;
  z-index: 500;
  /*box-shadow: 2px 2px 2px 2px #f0f0f0;*/
  border-radius: 6px;
  padding: 2rem;
  background: #ffffff;
}
</style>
